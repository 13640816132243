import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/cuenta',
        name: 'MiCuenta',
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/MiCuenta'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/usuarios',
        name: 'UsuariosListar',
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/usuarios/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/usuarios/create',
        name: 'UsuariosCrear',
        props: true,
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/usuarios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/usuarios/edit/:usuario_id',
        name: 'UsuariosEditar',
        props: true,
        component: () => import(/* webpackChunkName: "usuarios" */ '../views/usuarios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/clientes',
        name: 'ClientesListar',
        component: () => import(/* webpackChunkName: "clientes" */ '../views/clientes/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/clientes/create',
        name: 'ClientesCrear',
        props: true,
        component: () => import(/* webpackChunkName: "clientes" */ '../views/clientes/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/clientes/edit/:cliente_id',
        name: 'ClientesEditar',
        props: true,
        component: () => import(/* webpackChunkName: "clientes" */ '../views/clientes/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/sucursales',
        name: 'SucursalesListar',
        component: () => import(/* webpackChunkName: "sucursales" */ '../views/sucursales/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sucursales/create',
        name: 'SucursalesCrear',
        props: true,
        component: () => import(/* webpackChunkName: "sucursales" */ '../views/sucursales/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sucursales/edit/:sucursal_id',
        name: 'SucursalesEditar',
        props: true,
        component: () => import(/* webpackChunkName: "sucursales" */ '../views/sucursales/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/areas',
        name: 'AreasListar',
        component: () => import(/* webpackChunkName: "areas" */ '../views/areas/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/areas/create',
        name: 'AreasCrear',
        props: true,
        component: () => import(/* webpackChunkName: "areas" */ '../views/areas/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/areas/edit/:area_id',
        name: 'AreasEditar',
        props: true,
        component: () => import(/* webpackChunkName: "areas" */ '../views/areas/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/estudios',
        name: 'EstudiosListar',
        component: () => import(/* webpackChunkName: "estudios" */ '../views/estudios/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/estudios/create',
        name: 'EstudiosCrear',
        props: true,
        component: () => import(/* webpackChunkName: "estudios" */ '../views/estudios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/estudios/edit/:estudio_id',
        name: 'EstudiosEditar',
        props: true,
        component: () => import(/* webpackChunkName: "estudios" */ '../views/estudios/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/tiposCita',
        name: 'TiposCitaListar',
        component: () => import(/* webpackChunkName: "tiposCita" */ '../views/tiposCita/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tiposCita/create',
        name: 'TiposCitaCrear',
        props: true,
        component: () => import(/* webpackChunkName: "tiposCita" */ '../views/tiposCita/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tiposCita/edit/:tipo_cita_id',
        name: 'TiposCitaEditar',
        props: true,
        component: () => import(/* webpackChunkName: "tiposCita" */ '../views/tiposCita/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/monitores',
        name: 'MonitoresListar',
        props: true,
        component: () => import(/* webpackChunkName: "monitores" */ '../views/monitores/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/monitores/create',
        name: 'MonitoresCrear',
        props: true,
        component: () => import(/* webpackChunkName: "monitores" */ '../views/monitores/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/monitores/edit/:monitor_id',
        name: 'MonitoresEditar',
        props: true,
        component: () => import(/* webpackChunkName: "monitores" */ '../views/monitores/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/cajas',
        name: 'CajasListar',
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/Lista'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cajas/create',
        name: 'CajasCrear',
        props: true,
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cajas/edit/:caja_id',
        name: 'CajasEditar',
        props: true,
        component: () => import(/* webpackChunkName: "cajas" */ '../views/cajas/CrearEditar'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/estadisticas',
        name: 'EstadisticasBase',
        props: true,
        component: () => import(/* webpackChunkName: "estadisticas" */ '../views/estadisticas/Base'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/historial_turnos',
        name: 'HistorialTurnos',
        props: true,
        component: () => import(/* webpackChunkName: "historial" */ '../views/historial/Turnos'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/encuestas_config',
        name: 'EncuestaConfig',
        props: true,
        component: () => import(/* webpackChunkName: "encuestas_admin" */ '../views/encuestas/EncuestaConfig'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/encuestas_estadisticas',
        name: 'EncuestaStats',
        props: true,
        component: () => import(/* webpackChunkName: "encuestas_admin" */ '../views/encuestas/EncuestaStats'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/speech_config',
        name: 'SpeechConfig',
        props: true,
        component: () => import(/* webpackChunkName: "config" */ '../views/config/Speech'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/turnos/:sucursal_id',
        name: 'SolicitarTurno',
        props: true,
        component: () => import(/* webpackChunkName: "turnos" */ '../views/turnos/SolicitarTurno'),
        meta: {
            requiresAuth: false,
            guest: true
        }
    },
    {
        path: '/encuestas/responder/:token',
        name: 'ResponderEncuesta',
        props: true,
        component: () => import(/* webpackChunkName: "responder_encuesta" */ '../views/encuestas/ResponderEncuesta'),
        meta: {
            requiresAuth: false,
            guest: true
        }
    },
    {
        path: '/turnos/:turno_id/view',
        name: 'ViewTurno',
        props: true,
        component: () => import(/* webpackChunkName: "turnos" */ '../views/turnos/ViewTurno'),
        meta: {
            requiresAuth: false,
            guest: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    function tryLogin(onOk, onError) {
        axios.get('/api/user')
            .then(res => {
                store.commit('setUserData', res.data.data.user);
                store.commit('setIsLoggedIn', true);
                store.commit('setPermisos', res.data.data.permisos);
                onOk();
            })
            .catch(err => {
                onError();
            })
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            next();
        } else {
            tryLogin(() => {
                next()
            }, () => {
                next({
                    path: '/login',
                    params: {nextUrl: to.fullPath}
                });
            });
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            next({name: 'Home'})
        } else {
            tryLogin(() => {
                next({name: 'Home'});
            }, () => {
                next();
            });
        }
    } else {
        tryLogin(() => {
            next();
        }, () => {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            });
        });
    }
});

export default router
