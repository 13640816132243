<template>
    <div id="app">
        <div class="vw-100 vh-100 d-flex flex-row">
            <main-menu v-if="is_logged_in"></main-menu>
            <div class="flex-grow-1" style="max-height: 100%; overflow-y: auto">
                <router-view/>
            </div>
            <flash-alert></flash-alert>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import MainMenu from "./components/home/MainMenu";

    export default {
        name: 'App',
        components: {
            MainMenu,
        },
        computed: {
            ...mapState({
                is_logged_in: state => state.user.is_logged_in,
            })
        }
    }
</script>


