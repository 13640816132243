<template>
  <div class="vw-100 vh-100 row g-0">
    <div class="col-12 col-sm-4 col-lg-3 col-xl-2 bg-primary p-3">
      <div class="d-flex flex-column h-100">
        <div class="p-2" v-if="theme === 'chopo'">
          <img src="../assets/logo blanco.png" class="w-100 rounded">
        </div>
        <div class="p-2" v-if="theme === 'demo'">
          <img src="../assets/logo_tf_blanco_solo.png" class="w-100 rounded">
        </div>
        <div class="p-2" v-if="theme === 'tolsa'">
          <img src="../assets/login_logo.png" class="w-100">
        </div>
        <div class="flex-grow-1 mt-3">
          <div>
            <label class="form-label mb-0 text-light">Email</label>
            <input name="email" type="text" class="form-control" v-model="email">
            <form-error name="email" :errors="errors"></form-error>
          </div>
          <div>
            <label class="form-label mb-0 text-light">Contraseña</label>
            <input name="password" type="password" class="form-control" v-model="password"
                   @keyup.enter="login">
            <form-error name="password" :errors="errors"></form-error>
          </div>
          <div>
            <button :disabled="working"
                    class="btn btn-outline-light w-100 mt-3" @click="login">
              <i v-if="working" class="fa fa-spin fa-cog"></i> Iniciar sesión
            </button>
          </div>
        </div>
        <div class="p-4">
          <img src="../assets/dev_by_white.png" class="w-100">
        </div>
      </div>

    </div>
    <div class="d-none d-sm-block col-sm-8 col-lg-9 col-xl-10 text-light"
         :class="{'login-bg-generic': theme === 'chopo' || theme === 'demo',
         'login-bg-tolsa': theme === 'tolsa'}">
      <div class="p-4 lh-1">
        <h4 class="mb-0">{{ project_name }}</h4>
        <span>Bienvenido</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      project_name: process.env.VUE_APP_NAME ?? 'Tresfactorial',
      email: null,
      password: null,
      errors: [],
      working: false,
      theme: process.env.VUE_APP_THEME
    }
  },
  methods: {
    login() {
      this.errors = [];
      this.working = true;
      axios.post('/login', {
        email: this.email,
        password: this.password,
      }).then(response => {
        this.$router.replace('/');
      }).catch(error => {
        this.errors = error.response.data.errors;
        this.working = false;
      });
    }
  }
}
</script>

<style>
.login-bg-generic {
  background: url("../assets/login_bg.jpg") center;
  background-size: cover;
}

.login-bg-tolsa {
  background: url("../assets/login_bg_tolsa.png") center;
  background-size: cover;
}
</style>