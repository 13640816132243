<template>
    <input :style="'width:'+width+'px; height:'+height+'px;'" :class="'form-control datepicker '+ (sm ? 'form-control-sm' : '')"
           ref="input" :value="value" @change="updateDatePicker"
           :present="present"/>
</template>

<script>
    import Vue from 'vue';

    export default {
        props: {
            width: {
                type: Number
            },
            height: {
                type: Number
            },
            value: {
                type: String
            },
            present: {
                type: Boolean
            },
            sm: {
                type:Boolean,
                default: false
            },
            format:{
                type: String,
                default:'dd/mm/yyyy'
            },
            bymonth:{
                type: Boolean,
                default:false
            }
        },
        mounted: function () {
            let self = this;
            Vue.nextTick(function () {
                self.buildDatePicker();
            });
        },
        methods: {
            buildDatePicker: function () {
                let self = this;
                let input = $(self.$refs.input);

                let options = {
                    todayHighlight: this.present,
                    autoclose: true,
                    format: "dd/mm/yyyy",
                    language: 'es'
                };

                if(this.bymonth){
                    options.format = "MM yyyy";
                    options.viewMode = "months";
                    options.minViewMode = "months";
                }

                if (this.present) {
                    options['startDate'] = new Date();
                }
                input.datepicker(options).on('changeDate', function (e) {
                    let date = e.format(options.format);
                    self.$emit('input', date);
                }).on('clearDate', function () {
                    self.$emit('input', '');
                }).on('blur', function (e) {
                    self.$emit('input', e.currentTarget.value);
                });
            },
            updateDatePicker: function () {
                let input = $(this.$refs.input);
                input.datepicker("update", this.$refs.input.value);
                this.$emit('input', this.$refs.input.value);
            }
        },
        watch: {

            value: function () {

                let input = $(this.$refs.input);

                input.datepicker("update", this.value);

            }

        },
    }
</script>

<style scoped>
</style>
