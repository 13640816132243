<template>
  <div style="min-width: 200px; max-width: 200px;" class="bg-primary p-2">
    <div class="d-flex flex-column h-100">
      <div class="p-2" v-if="theme === 'chopo'">
        <img src="../../assets/logo blanco.png" class="w-100 rounded">
      </div>
      <div class="p-2" v-if="theme === 'demo'">
        <img src="../../assets/logo_tf_blanco_solo.png" class="w-100 rounded">
      </div>
      <div class="p-2" v-if="theme === 'tolsa'">
        <img src="../../assets/login_logo.png" class="w-100">
      </div>
      <div class="flex-grow-1 my-3 text-center overflow-auto">
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/">
          Dashboard
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/usuarios"
                     v-if="$permisos.can('usuarios.menu')">
          Usuarios
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/clientes"
                     v-if="$permisos.can('clientes.menu')">
          Clientes
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/sucursales"
                     v-if="$permisos.can('sucursales.menu')">
          Sucursales
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/areas"
                     v-if="$permisos.can('areas.menu')">
          Áreas
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/cajas"
                     v-if="$permisos.can('cajas.menu')">
          Cajas
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/estudios"
                     v-if="$permisos.can('estudios.menu')">
          Estudios
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/tiposCita"
                     v-if="$permisos.can('tiposCita.menu')">
          Tipos de filas
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/monitores"
                     v-if="$permisos.can('monitores.menu')">
          Monitores
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/estadisticas"
                     v-if="$permisos.can('estadisticas.menu')">
          Estadísticas
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/historial_turnos"
                     v-if="$permisos.can('historial.menu')">
          Consulta historial
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/encuestas_config"
                     v-if="$permisos.can('encuestas_config.menu')">
          Configuración de encuesta
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/encuestas_estadisticas"
                     v-if="$permisos.can('encuestas_estadisticas.menu')">
          Estadísticas de encuesta
        </router-link>
        <router-link class="link-light text-decoration-none d-block w-100 mb-1" to="/speech_config"
                     v-if="$permisos.can('speech_config.menu')">
          Configuración de voz
        </router-link>
      </div>
      <div class="my-2 text-center">
        <router-link class="link-light text-decoration-none d-block w-100 mb-1 h5" to="/cuenta">
          <i class="fa fa-user me-2"></i> Mi cuenta
        </router-link>
        <button @click="logout" class="btn btn-sm btn-outline-light w-100">
          Cerrar sesión
        </button>
      </div>
      <div class="p-2">
        <img src="../../assets/dev_by_white.png" class="w-100">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Menu',
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
    }
  },
  methods: {
    logout() {
      axios.post('/logout').then(() => {
        this.$store.commit('setUserData', null);
        this.$store.commit('setIsLoggedIn', false);
        this.$router.replace('/login');
      });
    }
  }
}
</script>